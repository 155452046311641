import { default as indexoKaViAW06AMeta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20241115195719/pages/index.vue?macro=true";
import { default as _91id_93MX4Nl0FFgTMeta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20241115195719/pages/inventory/bundle-choices/[id].vue?macro=true";
import { default as indexKYFBkltecsMeta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20241115195719/pages/inventory/bundle-choices/index.vue?macro=true";
import { default as _91id_93MqSE78VjxQMeta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20241115195719/pages/inventory/bundles/[id].vue?macro=true";
import { default as indexUw42jxdBsKMeta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20241115195719/pages/inventory/bundles/index.vue?macro=true";
import { default as _91menuCategoryId_93SMJJOapw3HMeta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20241115195719/pages/inventory/menus/[id]/categories/[menuCategoryId].vue?macro=true";
import { default as indexHQMOY8sks7Meta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20241115195719/pages/inventory/menus/[id]/index.vue?macro=true";
import { default as indexFiGL1BTrlvMeta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20241115195719/pages/inventory/menus/index.vue?macro=true";
import { default as indexoN8Y2AWC8TMeta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20241115195719/pages/inventory/modifiers/index.vue?macro=true";
import { default as index5TgJh9KYIiMeta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20241115195719/pages/inventory/modifiers/modifier-groups/[id]/index.vue?macro=true";
import { default as _91modifierId_93kFO2zGzHd7Meta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20241115195719/pages/inventory/modifiers/modifier-groups/[id]/modifiers/[modifierId].vue?macro=true";
import { default as _91id_93XAPfK2ceZoMeta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20241115195719/pages/inventory/modifiers/modifier-groups/[id].vue?macro=true";
import { default as index6OFjAOxUo2Meta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20241115195719/pages/inventory/modifiers/modifier-groups/index.vue?macro=true";
import { default as indexKXknw0q5uJMeta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20241115195719/pages/inventory/products/index.vue?macro=true";
import { default as _91id_934Ta3w1S2DhMeta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20241115195719/pages/inventory/products/product-categories/[id].vue?macro=true";
import { default as indexqUMGfMW1VhMeta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20241115195719/pages/inventory/products/product-categories/index.vue?macro=true";
import { default as _91id_93tX1oOoEI4tMeta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20241115195719/pages/inventory/products/products/[id].vue?macro=true";
import { default as indexNakIv4Cp3qMeta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20241115195719/pages/inventory/products/products/index.vue?macro=true";
import { default as merchantsTX9U6ZHVcVMeta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20241115195719/pages/merchants.vue?macro=true";
import { default as _91accountId_932WP9DLT1FVMeta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20241115195719/pages/point-of-sale/account-configurations/[id]/accounts/[accountId].vue?macro=true";
import { default as indexYHRjqWpQP1Meta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20241115195719/pages/point-of-sale/account-configurations/[id]/index.vue?macro=true";
import { default as _91id_93TAlaASaJGCMeta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20241115195719/pages/point-of-sale/account-configurations/[id].vue?macro=true";
import { default as indexxYDI8MSVulMeta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20241115195719/pages/point-of-sale/account-configurations/index.vue?macro=true";
import { default as credentialsC3qm4Ssdb3Meta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20241115195719/pages/point-of-sale/connections/[id]/credentials.vue?macro=true";
import { default as indexKcXQckliOlMeta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20241115195719/pages/point-of-sale/connections/[id]/index.vue?macro=true";
import { default as _91id_93H8CgFjoqHZMeta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20241115195719/pages/point-of-sale/connections/[id].vue?macro=true";
import { default as _91driver_93RM0USlLd6jMeta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20241115195719/pages/point-of-sale/connections/create/[driver].vue?macro=true";
import { default as index6gyLcMjEG3Meta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20241115195719/pages/point-of-sale/connections/create/index.vue?macro=true";
import { default as index5kYv0MrdhsMeta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20241115195719/pages/point-of-sale/connections/index.vue?macro=true";
import { default as _91id_93BwB7YpSTeLMeta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20241115195719/pages/point-of-sale/discounts/discounts/[id].vue?macro=true";
import { default as indexrpQyHHv49FMeta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20241115195719/pages/point-of-sale/discounts/discounts/index.vue?macro=true";
import { default as indexDtIOrdGXJaMeta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20241115195719/pages/point-of-sale/discounts/index.vue?macro=true";
import { default as indexdwUUcFwz75Meta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20241115195719/pages/point-of-sale/employees/[id]/index.vue?macro=true";
import { default as logo4jZp4s7zBMeta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20241115195719/pages/point-of-sale/employees/[id]/log.vue?macro=true";
import { default as _91id_93YOOHNNkDYvMeta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20241115195719/pages/point-of-sale/employees/[id].vue?macro=true";
import { default as indexcmPS5FjCwiMeta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20241115195719/pages/point-of-sale/employees/index.vue?macro=true";
import { default as indexpdvDTqv84VMeta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20241115195719/pages/point-of-sale/images/index.vue?macro=true";
import { default as indexDnIPyQYZ29Meta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20241115195719/pages/point-of-sale/payments/index.vue?macro=true";
import { default as payment_45methodsS37Kzrxp0zMeta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20241115195719/pages/point-of-sale/payments/payment-methods.vue?macro=true";
import { default as configurationsNck9yZqrBCMeta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20241115195719/pages/point-of-sale/reservations/configurations.vue?macro=true";
import { default as index19by8CsfpqMeta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20241115195719/pages/point-of-sale/reservations/index.vue?macro=true";
import { default as configurationsE0p6GRTdI6Meta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20241115195719/pages/point-of-sale/reviews/configurations.vue?macro=true";
import { default as indexbinPgxC8zLMeta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20241115195719/pages/point-of-sale/reviews/index.vue?macro=true";
import { default as _91id_93RYps5sqPYbMeta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20241115195719/pages/point-of-sale/stock-categories/[id].vue?macro=true";
import { default as _91id_93ThtLnp892fMeta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20241115195719/pages/point-of-sale/stock-items/[id].vue?macro=true";
import { default as _91id_93IM8BleNdxvMeta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20241115195719/pages/point-of-sale/stock-statuses/[id].vue?macro=true";
import { default as stockQIoDkLPECiMeta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20241115195719/pages/point-of-sale/stock.vue?macro=true";
import { default as bookkeepingVXcJcIP2xkMeta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20241115195719/pages/point-of-sale/stores/[id]/bookkeeping.vue?macro=true";
import { default as _91floorId_937jWZf3bTs9Meta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20241115195719/pages/point-of-sale/stores/[id]/floors/[floorId].vue?macro=true";
import { default as indexuTCNbG9BYyMeta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20241115195719/pages/point-of-sale/stores/[id]/floors/index.vue?macro=true";
import { default as hardwareyVoGWqSBIgMeta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20241115195719/pages/point-of-sale/stores/[id]/hardware.vue?macro=true";
import { default as indexH36pCKAO75Meta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20241115195719/pages/point-of-sale/stores/[id]/index.vue?macro=true";
import { default as indexeQScLd55qMMeta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20241115195719/pages/point-of-sale/stores/[id]/kitchen/index.vue?macro=true";
import { default as _91kitchenStationId_93WENiGTK5k3Meta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20241115195719/pages/point-of-sale/stores/[id]/kitchen/kitchen-stations/[kitchenStationId].vue?macro=true";
import { default as stockqydIvmJ7V4Meta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20241115195719/pages/point-of-sale/stores/[id]/stock.vue?macro=true";
import { default as _91id_93bmgAd9cQyaMeta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20241115195719/pages/point-of-sale/stores/[id].vue?macro=true";
import { default as indexOxhqNMwokMMeta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20241115195719/pages/point-of-sale/stores/index.vue?macro=true";
import { default as indexoL2D2rqT2YMeta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20241115195719/pages/point-of-sale/terminals/colors/index.vue?macro=true";
import { default as _91id_93KjL1MTRKcpMeta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20241115195719/pages/point-of-sale/terminals/folders/[id].vue?macro=true";
import { default as indexoigLZ2d6kwMeta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20241115195719/pages/point-of-sale/terminals/folders/index.vue?macro=true";
import { default as indexsWB2e7NevkMeta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20241115195719/pages/point-of-sale/terminals/terminals/[id]/index.vue?macro=true";
import { default as logWj61HkMp0WMeta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20241115195719/pages/point-of-sale/terminals/terminals/[id]/log.vue?macro=true";
import { default as navigationnWHyR4tDdzMeta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20241115195719/pages/point-of-sale/terminals/terminals/[id]/navigation.vue?macro=true";
import { default as _91id_93JN7761i5opMeta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20241115195719/pages/point-of-sale/terminals/terminals/[id].vue?macro=true";
import { default as indexg4tluqaoAtMeta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20241115195719/pages/point-of-sale/terminals/terminals/index.vue?macro=true";
import { default as indexemgtho2h5hMeta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20241115195719/pages/profile/index.vue?macro=true";
import { default as downloadPw0yeFaCBxMeta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20241115195719/pages/reports/download.vue?macro=true";
import { default as efficiency2PTodylwT5Meta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20241115195719/pages/reports/efficiency.vue?macro=true";
import { default as indexTxcPTdYksJMeta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20241115195719/pages/reports/index.vue?macro=true";
import { default as productionCmIB2FDrtPMeta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20241115195719/pages/reports/production.vue?macro=true";
import { default as tipsMoVbTs734uMeta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20241115195719/pages/reports/tips.vue?macro=true";
import { default as indexj01rlum7wqMeta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20241115195719/pages/settings/index.vue?macro=true";
import { default as indexItA0eyxq44Meta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20241115195719/pages/settlement/cancellations/index.vue?macro=true";
import { default as reasonsjaTKCJOk8MMeta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20241115195719/pages/settlement/cancellations/reasons.vue?macro=true";
import { default as cash_45transfersyHVjJ2TCqcMeta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20241115195719/pages/settlement/cash-transfers/cash-transfers.vue?macro=true";
import { default as _91id_93bqAH6nTaZoMeta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20241115195719/pages/settlement/cash-transfers/categories/[id].vue?macro=true";
import { default as indexRkLvJTsXbaMeta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20241115195719/pages/settlement/cash-transfers/categories/index.vue?macro=true";
import { default as index3J1yJTlsVjMeta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20241115195719/pages/settlement/cash-transfers/index.vue?macro=true";
import { default as indexV1vwNy3IX3Meta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20241115195719/pages/settlement/channel-settlements/[id]/index.vue?macro=true";
import { default as logTGu0oIS7SxMeta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20241115195719/pages/settlement/channel-settlements/[id]/log.vue?macro=true";
import { default as ordersKvx1Kxb4QNMeta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20241115195719/pages/settlement/channel-settlements/[id]/orders.vue?macro=true";
import { default as _91channelSettlementSnapshotId_93ZV5RJMlh8XMeta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20241115195719/pages/settlement/channel-settlements/[id]/snapshots/[channelSettlementSnapshotId].vue?macro=true";
import { default as indexOgP29JmdGVMeta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20241115195719/pages/settlement/channel-settlements/[id]/snapshots/index.vue?macro=true";
import { default as _91id_93IV611YP5moMeta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20241115195719/pages/settlement/channel-settlements/[id].vue?macro=true";
import { default as _91id_93NtMFkz6xk3Meta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20241115195719/pages/settlement/delivery-providers/[id].vue?macro=true";
import { default as index5ygc66JEDmMeta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20241115195719/pages/settlement/delivery-providers/index.vue?macro=true";
import { default as _91id_93Ol20KJDwPnMeta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20241115195719/pages/settlement/order-providers/[id].vue?macro=true";
import { default as indexVUvvVTAZjnMeta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20241115195719/pages/settlement/order-providers/index.vue?macro=true";
import { default as _91id_93ybVmf0LdxFMeta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20241115195719/pages/settlement/orders/[id].vue?macro=true";
import { default as indexgwTL00BOqfMeta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20241115195719/pages/settlement/orders/index.vue?macro=true";
import { default as bookkeepingLqqedL8ehZMeta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20241115195719/pages/settlement/store-settlements/[id]/bookkeeping.vue?macro=true";
import { default as channel_45settlements8HY574L1J4Meta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20241115195719/pages/settlement/store-settlements/[id]/channel-settlements.vue?macro=true";
import { default as indexHbuOmRS4NNMeta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20241115195719/pages/settlement/store-settlements/[id]/index.vue?macro=true";
import { default as logAbtkUHiLlUMeta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20241115195719/pages/settlement/store-settlements/[id]/log.vue?macro=true";
import { default as orderseUWLc4JD24Meta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20241115195719/pages/settlement/store-settlements/[id]/orders.vue?macro=true";
import { default as _91reconciliationId_93mkqTWM6amxMeta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20241115195719/pages/settlement/store-settlements/[id]/reconciliations/[reconciliationId].vue?macro=true";
import { default as index1FArYkVvnHMeta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20241115195719/pages/settlement/store-settlements/[id]/reconciliations/index.vue?macro=true";
import { default as _91id_93Xp9HgMDB1CMeta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20241115195719/pages/settlement/store-settlements/[id].vue?macro=true";
import { default as closing_45errorsLcZ5F8fJrvMeta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20241115195719/pages/settlement/store-settlements/closing-errors.vue?macro=true";
import { default as indexYogclkBTT9Meta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20241115195719/pages/settlement/store-settlements/index.vue?macro=true";
export default [
  {
    name: "index",
    path: "/",
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20241115195719/pages/index.vue")
  },
  {
    name: "inventory-bundle-choices-id",
    path: "/inventory/bundle-choices/:id()",
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20241115195719/pages/inventory/bundle-choices/[id].vue")
  },
  {
    name: "inventory-bundle-choices",
    path: "/inventory/bundle-choices",
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20241115195719/pages/inventory/bundle-choices/index.vue")
  },
  {
    name: "inventory-bundles-id",
    path: "/inventory/bundles/:id()",
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20241115195719/pages/inventory/bundles/[id].vue")
  },
  {
    name: "inventory-bundles",
    path: "/inventory/bundles",
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20241115195719/pages/inventory/bundles/index.vue")
  },
  {
    name: "inventory-menus-id-categories-menuCategoryId",
    path: "/inventory/menus/:id()/categories/:menuCategoryId()",
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20241115195719/pages/inventory/menus/[id]/categories/[menuCategoryId].vue")
  },
  {
    name: "inventory-menus-id",
    path: "/inventory/menus/:id()",
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20241115195719/pages/inventory/menus/[id]/index.vue")
  },
  {
    name: "inventory-menus",
    path: "/inventory/menus",
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20241115195719/pages/inventory/menus/index.vue")
  },
  {
    name: "inventory-modifiers",
    path: "/inventory/modifiers",
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20241115195719/pages/inventory/modifiers/index.vue")
  },
  {
    name: _91id_93XAPfK2ceZoMeta?.name,
    path: "/inventory/modifiers/modifier-groups/:id()",
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20241115195719/pages/inventory/modifiers/modifier-groups/[id].vue"),
    children: [
  {
    name: "inventory-modifiers-modifier-groups-id",
    path: "",
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20241115195719/pages/inventory/modifiers/modifier-groups/[id]/index.vue")
  },
  {
    name: "inventory-modifiers-modifier-groups-id-modifiers-modifierId",
    path: "modifiers/:modifierId()",
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20241115195719/pages/inventory/modifiers/modifier-groups/[id]/modifiers/[modifierId].vue")
  }
]
  },
  {
    name: "inventory-modifiers-modifier-groups",
    path: "/inventory/modifiers/modifier-groups",
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20241115195719/pages/inventory/modifiers/modifier-groups/index.vue")
  },
  {
    name: "inventory-products",
    path: "/inventory/products",
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20241115195719/pages/inventory/products/index.vue")
  },
  {
    name: "inventory-products-product-categories-id",
    path: "/inventory/products/product-categories/:id()",
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20241115195719/pages/inventory/products/product-categories/[id].vue")
  },
  {
    name: "inventory-products-product-categories",
    path: "/inventory/products/product-categories",
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20241115195719/pages/inventory/products/product-categories/index.vue")
  },
  {
    name: "inventory-products-products-id",
    path: "/inventory/products/products/:id()",
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20241115195719/pages/inventory/products/products/[id].vue")
  },
  {
    name: "inventory-products-products",
    path: "/inventory/products/products",
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20241115195719/pages/inventory/products/products/index.vue")
  },
  {
    name: "merchants",
    path: "/merchants",
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20241115195719/pages/merchants.vue")
  },
  {
    name: _91id_93TAlaASaJGCMeta?.name,
    path: "/point-of-sale/account-configurations/:id()",
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20241115195719/pages/point-of-sale/account-configurations/[id].vue"),
    children: [
  {
    name: "point-of-sale-account-configurations-id-accounts-accountId",
    path: "accounts/:accountId()",
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20241115195719/pages/point-of-sale/account-configurations/[id]/accounts/[accountId].vue")
  },
  {
    name: "point-of-sale-account-configurations-id",
    path: "",
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20241115195719/pages/point-of-sale/account-configurations/[id]/index.vue")
  }
]
  },
  {
    name: "point-of-sale-account-configurations",
    path: "/point-of-sale/account-configurations",
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20241115195719/pages/point-of-sale/account-configurations/index.vue")
  },
  {
    name: _91id_93H8CgFjoqHZMeta?.name,
    path: "/point-of-sale/connections/:id()",
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20241115195719/pages/point-of-sale/connections/[id].vue"),
    children: [
  {
    name: "point-of-sale-connections-id-credentials",
    path: "credentials",
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20241115195719/pages/point-of-sale/connections/[id]/credentials.vue")
  },
  {
    name: "point-of-sale-connections-id",
    path: "",
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20241115195719/pages/point-of-sale/connections/[id]/index.vue")
  }
]
  },
  {
    name: "point-of-sale-connections-create-driver",
    path: "/point-of-sale/connections/create/:driver()",
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20241115195719/pages/point-of-sale/connections/create/[driver].vue")
  },
  {
    name: "point-of-sale-connections-create",
    path: "/point-of-sale/connections/create",
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20241115195719/pages/point-of-sale/connections/create/index.vue")
  },
  {
    name: "point-of-sale-connections",
    path: "/point-of-sale/connections",
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20241115195719/pages/point-of-sale/connections/index.vue")
  },
  {
    name: "point-of-sale-discounts-discounts-id",
    path: "/point-of-sale/discounts/discounts/:id()",
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20241115195719/pages/point-of-sale/discounts/discounts/[id].vue")
  },
  {
    name: "point-of-sale-discounts-discounts",
    path: "/point-of-sale/discounts/discounts",
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20241115195719/pages/point-of-sale/discounts/discounts/index.vue")
  },
  {
    name: "point-of-sale-discounts",
    path: "/point-of-sale/discounts",
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20241115195719/pages/point-of-sale/discounts/index.vue")
  },
  {
    name: _91id_93YOOHNNkDYvMeta?.name,
    path: "/point-of-sale/employees/:id()",
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20241115195719/pages/point-of-sale/employees/[id].vue"),
    children: [
  {
    name: "point-of-sale-employees-id",
    path: "",
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20241115195719/pages/point-of-sale/employees/[id]/index.vue")
  },
  {
    name: "point-of-sale-employees-id-log",
    path: "log",
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20241115195719/pages/point-of-sale/employees/[id]/log.vue")
  }
]
  },
  {
    name: "point-of-sale-employees",
    path: "/point-of-sale/employees",
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20241115195719/pages/point-of-sale/employees/index.vue")
  },
  {
    name: "point-of-sale-images",
    path: "/point-of-sale/images",
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20241115195719/pages/point-of-sale/images/index.vue")
  },
  {
    name: "point-of-sale-payments",
    path: "/point-of-sale/payments",
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20241115195719/pages/point-of-sale/payments/index.vue")
  },
  {
    name: "point-of-sale-payments-payment-methods",
    path: "/point-of-sale/payments/payment-methods",
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20241115195719/pages/point-of-sale/payments/payment-methods.vue")
  },
  {
    name: "point-of-sale-reservations-configurations",
    path: "/point-of-sale/reservations/configurations",
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20241115195719/pages/point-of-sale/reservations/configurations.vue")
  },
  {
    name: "point-of-sale-reservations",
    path: "/point-of-sale/reservations",
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20241115195719/pages/point-of-sale/reservations/index.vue")
  },
  {
    name: "point-of-sale-reviews-configurations",
    path: "/point-of-sale/reviews/configurations",
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20241115195719/pages/point-of-sale/reviews/configurations.vue")
  },
  {
    name: "point-of-sale-reviews",
    path: "/point-of-sale/reviews",
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20241115195719/pages/point-of-sale/reviews/index.vue")
  },
  {
    name: "point-of-sale-stock-categories-id",
    path: "/point-of-sale/stock-categories/:id()",
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20241115195719/pages/point-of-sale/stock-categories/[id].vue")
  },
  {
    name: "point-of-sale-stock-items-id",
    path: "/point-of-sale/stock-items/:id()",
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20241115195719/pages/point-of-sale/stock-items/[id].vue")
  },
  {
    name: "point-of-sale-stock-statuses-id",
    path: "/point-of-sale/stock-statuses/:id()",
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20241115195719/pages/point-of-sale/stock-statuses/[id].vue")
  },
  {
    name: "point-of-sale-stock",
    path: "/point-of-sale/stock",
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20241115195719/pages/point-of-sale/stock.vue")
  },
  {
    name: _91id_93bmgAd9cQyaMeta?.name,
    path: "/point-of-sale/stores/:id()",
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20241115195719/pages/point-of-sale/stores/[id].vue"),
    children: [
  {
    name: "point-of-sale-stores-id-bookkeeping",
    path: "bookkeeping",
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20241115195719/pages/point-of-sale/stores/[id]/bookkeeping.vue")
  },
  {
    name: "point-of-sale-stores-id-floors-floorId",
    path: "floors/:floorId()",
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20241115195719/pages/point-of-sale/stores/[id]/floors/[floorId].vue")
  },
  {
    name: "point-of-sale-stores-id-floors",
    path: "floors",
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20241115195719/pages/point-of-sale/stores/[id]/floors/index.vue")
  },
  {
    name: "point-of-sale-stores-id-hardware",
    path: "hardware",
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20241115195719/pages/point-of-sale/stores/[id]/hardware.vue")
  },
  {
    name: "point-of-sale-stores-id",
    path: "",
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20241115195719/pages/point-of-sale/stores/[id]/index.vue")
  },
  {
    name: "point-of-sale-stores-id-kitchen",
    path: "kitchen",
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20241115195719/pages/point-of-sale/stores/[id]/kitchen/index.vue")
  },
  {
    name: "point-of-sale-stores-id-kitchen-kitchen-stations-kitchenStationId",
    path: "kitchen/kitchen-stations/:kitchenStationId()",
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20241115195719/pages/point-of-sale/stores/[id]/kitchen/kitchen-stations/[kitchenStationId].vue")
  },
  {
    name: "point-of-sale-stores-id-stock",
    path: "stock",
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20241115195719/pages/point-of-sale/stores/[id]/stock.vue")
  }
]
  },
  {
    name: "point-of-sale-stores",
    path: "/point-of-sale/stores",
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20241115195719/pages/point-of-sale/stores/index.vue")
  },
  {
    name: "point-of-sale-terminals-colors",
    path: "/point-of-sale/terminals/colors",
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20241115195719/pages/point-of-sale/terminals/colors/index.vue")
  },
  {
    name: "point-of-sale-terminals-folders-id",
    path: "/point-of-sale/terminals/folders/:id()",
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20241115195719/pages/point-of-sale/terminals/folders/[id].vue")
  },
  {
    name: "point-of-sale-terminals-folders",
    path: "/point-of-sale/terminals/folders",
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20241115195719/pages/point-of-sale/terminals/folders/index.vue")
  },
  {
    name: _91id_93JN7761i5opMeta?.name,
    path: "/point-of-sale/terminals/terminals/:id()",
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20241115195719/pages/point-of-sale/terminals/terminals/[id].vue"),
    children: [
  {
    name: "point-of-sale-terminals-terminals-id",
    path: "",
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20241115195719/pages/point-of-sale/terminals/terminals/[id]/index.vue")
  },
  {
    name: "point-of-sale-terminals-terminals-id-log",
    path: "log",
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20241115195719/pages/point-of-sale/terminals/terminals/[id]/log.vue")
  },
  {
    name: "point-of-sale-terminals-terminals-id-navigation",
    path: "navigation",
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20241115195719/pages/point-of-sale/terminals/terminals/[id]/navigation.vue")
  }
]
  },
  {
    name: "point-of-sale-terminals-terminals",
    path: "/point-of-sale/terminals/terminals",
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20241115195719/pages/point-of-sale/terminals/terminals/index.vue")
  },
  {
    name: "profile",
    path: "/profile",
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20241115195719/pages/profile/index.vue")
  },
  {
    name: "reports-download",
    path: "/reports/download",
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20241115195719/pages/reports/download.vue")
  },
  {
    name: "reports-efficiency",
    path: "/reports/efficiency",
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20241115195719/pages/reports/efficiency.vue")
  },
  {
    name: "reports",
    path: "/reports",
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20241115195719/pages/reports/index.vue")
  },
  {
    name: "reports-production",
    path: "/reports/production",
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20241115195719/pages/reports/production.vue")
  },
  {
    name: "reports-tips",
    path: "/reports/tips",
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20241115195719/pages/reports/tips.vue")
  },
  {
    name: "settings",
    path: "/settings",
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20241115195719/pages/settings/index.vue")
  },
  {
    name: "settlement-cancellations",
    path: "/settlement/cancellations",
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20241115195719/pages/settlement/cancellations/index.vue")
  },
  {
    name: "settlement-cancellations-reasons",
    path: "/settlement/cancellations/reasons",
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20241115195719/pages/settlement/cancellations/reasons.vue")
  },
  {
    name: "settlement-cash-transfers-cash-transfers",
    path: "/settlement/cash-transfers/cash-transfers",
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20241115195719/pages/settlement/cash-transfers/cash-transfers.vue")
  },
  {
    name: "settlement-cash-transfers-categories-id",
    path: "/settlement/cash-transfers/categories/:id()",
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20241115195719/pages/settlement/cash-transfers/categories/[id].vue")
  },
  {
    name: "settlement-cash-transfers-categories",
    path: "/settlement/cash-transfers/categories",
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20241115195719/pages/settlement/cash-transfers/categories/index.vue")
  },
  {
    name: "settlement-cash-transfers",
    path: "/settlement/cash-transfers",
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20241115195719/pages/settlement/cash-transfers/index.vue")
  },
  {
    name: _91id_93IV611YP5moMeta?.name,
    path: "/settlement/channel-settlements/:id()",
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20241115195719/pages/settlement/channel-settlements/[id].vue"),
    children: [
  {
    name: "settlement-channel-settlements-id",
    path: "",
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20241115195719/pages/settlement/channel-settlements/[id]/index.vue")
  },
  {
    name: "settlement-channel-settlements-id-log",
    path: "log",
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20241115195719/pages/settlement/channel-settlements/[id]/log.vue")
  },
  {
    name: "settlement-channel-settlements-id-orders",
    path: "orders",
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20241115195719/pages/settlement/channel-settlements/[id]/orders.vue")
  },
  {
    name: "settlement-channel-settlements-id-snapshots-channelSettlementSnapshotId",
    path: "snapshots/:channelSettlementSnapshotId()",
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20241115195719/pages/settlement/channel-settlements/[id]/snapshots/[channelSettlementSnapshotId].vue")
  },
  {
    name: "settlement-channel-settlements-id-snapshots",
    path: "snapshots",
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20241115195719/pages/settlement/channel-settlements/[id]/snapshots/index.vue")
  }
]
  },
  {
    name: "settlement-delivery-providers-id",
    path: "/settlement/delivery-providers/:id()",
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20241115195719/pages/settlement/delivery-providers/[id].vue")
  },
  {
    name: "settlement-delivery-providers",
    path: "/settlement/delivery-providers",
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20241115195719/pages/settlement/delivery-providers/index.vue")
  },
  {
    name: "settlement-order-providers-id",
    path: "/settlement/order-providers/:id()",
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20241115195719/pages/settlement/order-providers/[id].vue")
  },
  {
    name: "settlement-order-providers",
    path: "/settlement/order-providers",
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20241115195719/pages/settlement/order-providers/index.vue")
  },
  {
    name: "settlement-orders-id",
    path: "/settlement/orders/:id()",
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20241115195719/pages/settlement/orders/[id].vue")
  },
  {
    name: "settlement-orders",
    path: "/settlement/orders",
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20241115195719/pages/settlement/orders/index.vue")
  },
  {
    name: _91id_93Xp9HgMDB1CMeta?.name,
    path: "/settlement/store-settlements/:id()",
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20241115195719/pages/settlement/store-settlements/[id].vue"),
    children: [
  {
    name: "settlement-store-settlements-id-bookkeeping",
    path: "bookkeeping",
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20241115195719/pages/settlement/store-settlements/[id]/bookkeeping.vue")
  },
  {
    name: "settlement-store-settlements-id-channel-settlements",
    path: "channel-settlements",
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20241115195719/pages/settlement/store-settlements/[id]/channel-settlements.vue")
  },
  {
    name: "settlement-store-settlements-id",
    path: "",
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20241115195719/pages/settlement/store-settlements/[id]/index.vue")
  },
  {
    name: "settlement-store-settlements-id-log",
    path: "log",
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20241115195719/pages/settlement/store-settlements/[id]/log.vue")
  },
  {
    name: "settlement-store-settlements-id-orders",
    path: "orders",
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20241115195719/pages/settlement/store-settlements/[id]/orders.vue")
  },
  {
    name: "settlement-store-settlements-id-reconciliations-reconciliationId",
    path: "reconciliations/:reconciliationId()",
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20241115195719/pages/settlement/store-settlements/[id]/reconciliations/[reconciliationId].vue")
  },
  {
    name: "settlement-store-settlements-id-reconciliations",
    path: "reconciliations",
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20241115195719/pages/settlement/store-settlements/[id]/reconciliations/index.vue")
  }
]
  },
  {
    name: "settlement-store-settlements-closing-errors",
    path: "/settlement/store-settlements/closing-errors",
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20241115195719/pages/settlement/store-settlements/closing-errors.vue")
  },
  {
    name: "settlement-store-settlements",
    path: "/settlement/store-settlements",
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20241115195719/pages/settlement/store-settlements/index.vue")
  }
]
import { defineNuxtPlugin } from "#app";
import { useAuth } from "~/stores/auth.js";

export default defineNuxtPlugin(async () => {
  const auth = useAuth();

  if (
    !auth.merchantAccess ||
    !auth.merchantAccesses
      .map((merchantAccess) => merchantAccess.id)
      .includes(auth.merchantAccess.id)
  ) {
    auth.setMerchantAccess(auth.merchantAccesses[0]);
  }
});
